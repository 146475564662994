import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  'en': {
    searchBtn: 'Track',
    searchbarPlaceholder: 'Please enter your tracking number',
    backBtn: 'Go back',
    modalTitle: 'Transport status',
    trackingNumber: 'Tracking number',
    lastPosition: 'Last position',
    onGoingTransport: 'Transport in pending',
    dataPrivacy: 'Data privacy policy',
    cgu: 'CGU'
  },

  'fr': {
    searchBtn: 'Suivre',
    searchbarPlaceholder: 'Veuillez renseigner votre numéro de suivi',
    backBtn: 'Retour',
    modalTitle: 'Statut du transport',
    trackingNumber: 'Numéro de suivi',
    lastPosition: 'Dernière position',
    onGoingTransport: 'Transport en cours',
    dataPrivacy: 'Politique de confidentialité des données',
    cgu: 'CGU'
  }
};

const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
