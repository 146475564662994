import $dataService from "@/services/data.service"

export default {
  namespaced: true,

  state: {
    data: []
  },

  mutations: {
    SET_ALL_DATA(state, value) {
      state.data = value
    },
  },

  getters: {
    getData: state => state.data
  },

  actions: {
    async getAllDataAction ({ commit }) {
      try {
        const response = await $dataService.getAllData()
        commit('SET_ALL_DATA', response.data.result)
        return response.data.result
      } catch (error) {
        console.log(error)
      }
    },
  }
}