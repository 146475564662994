import axios from 'axios'

const baseURL = {
  server: "https://sliafrika.com/flexpi/",
}

const http = axios.create({
  baseURL: baseURL.server,
})

http.interceptors.request.use(function (config) {
  let token = localStorage.getItem('token');
  token ? config.headers['Authorization'] = `Bearer ${token}` : null
  return config;
}, function (error) {
  return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});

export default http

